import { TravelAgencyIcon } from "@twocontinents/shared-ui";
import {
  BookIcon,
  BookUserIcon,
  Building,
  DollarSign,
  FerrisWheel,
  GanttChartSquare,
  LogOutIcon,
  Notebook,
  Ship,
  ShoppingCart,
  Star,
  Ticket,
} from "lucide-react";

import { useAuth } from "../../auth";
import { routes } from "../../utils";
import { NavbarButton } from "./navbar-button";
import {
  NavbarDropdownItem,
  NavbarItemWithDropdownProps,
} from "./navbar-dropdown-item";
import { NavbarItem, type NavbarItemProps } from "./navbar-item";

const ICON_SIZE = 16;

const navbarItemsWithDropdown: Omit<NavbarItemWithDropdownProps, "pathname">[] =
  [
    {
      title: "Tickets",
      icon: <Ticket size={ICON_SIZE} />,
      labels: [
        {
          label: "Generator",
          to: routes.ticketGenerator.generator,
          icon: <Ticket size={ICON_SIZE} />,
        },
        {
          label: "History",
          to: routes.ticketGenerator.history,
          icon: <BookIcon size={ICON_SIZE} />,
        },
      ],
    },
  ];

export const Aside = () => {
  const { signOut, user } = useAuth();

  const navbarItems: Omit<NavbarItemProps, "pathname">[] = [
    {
      label: "Statistics",
      to: routes.home,
      icon: <GanttChartSquare size={ICON_SIZE} />,
    },
    {
      label: "Coupons",
      to: routes.attractions.coupons.index,
      icon: <GanttChartSquare size={ICON_SIZE} />,
    },
    {
      label: "Attractions",
      icon: <FerrisWheel size={ICON_SIZE} />,
      to: routes.attractions.index.index,
    },
    {
      label: "Attraction Orders",
      to: routes.attractions.orders.index,
      icon: <ShoppingCart size={ICON_SIZE} />,
    },
    {
      label: "Reviews",
      to: routes.attractions.reviews.index,
      icon: <Star size={ICON_SIZE} />,
    },
    {
      label: "Tours",
      to: routes.attractions.tours.index,
      icon: <Ship size={ICON_SIZE} />,
    },
    {
      label: "Apartments",
      icon: <Building size={ICON_SIZE} />,
      to: routes.stays.apartments.index,
    },
    {
      label: "Booking Orders",
      to: routes.stays.orders.index,
      icon: <ShoppingCart size={ICON_SIZE} />,
    },
    {
      label: "Financial log",
      to: routes.financialLog.index,
      icon: <Notebook size={ICON_SIZE} />,
    },
    {
      label: "Payment Link Generator",
      to: routes.paymentLinkGenerator,
      icon: <DollarSign size={ICON_SIZE} />,
    },
  ];

  const adminNavbarItems: Omit<NavbarItemProps, "pathname">[] = [
    {
      label: "Business Partners",
      icon: <BookUserIcon fontSize={"small"} />,
      to: routes.businessPartners.index,
    },
    {
      label: "Travel agencies",
      to: routes.travelAgencies.index,
      icon: <TravelAgencyIcon color={"white"} />,
    },
  ];

  if (user?.isRoot) {
    navbarItems.push(...adminNavbarItems);
  }

  return (
    <aside className="sticky top-0 flex min-w-52 flex-col gap-2 self-start pt-12">
      {navbarItems.map((item, index) => (
        <NavbarItem
          key={index}
          icon={item.icon}
          to={item.to}
          label={item.label}
        />
      ))}
      {/*  eslint-disable-next-line sonarjs/no-empty-collection */}
      {navbarItemsWithDropdown.map((item, index) => (
        <NavbarDropdownItem
          key={index}
          title={item.title}
          labels={item.labels}
          icon={item.icon}
        />
      ))}

      <NavbarButton
        icon={<LogOutIcon size={ICON_SIZE} />}
        onClick={signOut}
        label={"Wyloguj się"}
      />
    </aside>
  );
};
